import React, { useState } from "react";
import Countdown from "react-countdown";
import "./CountdownTimer.css";

export default function CountdownTimer() {
  // Initialize state with the initial target date.
  const [targetDate, setTargetDate] = useState(
    new Date("2025-02-21T16:00:00-05:00").getTime()
  );

  // onComplete is called when the countdown finishes.
  const handleComplete = () => {
    // Update the target date to a new value.
    setTargetDate(new Date("2025-02-23T16:00:00-05:00").getTime());
    // Returning false will restart the countdown with the new target.
    return false;
  };

  // Custom renderer for the countdown display.
  const renderer = ({ days, hours, minutes, seconds }) => {
    const d = String(days).padStart(2, "0");
    const h = String(hours).padStart(2, "0");
    const m = String(minutes).padStart(2, "0");
    const s = String(seconds).padStart(2, "0");

    return (
      <div className="countdown-container">
        <div className="time-block">
          <span className="value">{d}</span>
          <span className="label">Days</span>
        </div>
        <div className="time-block">
          <span className="value">{h}</span>
          <span className="label">Hours</span>
        </div>
        <div className="time-block">
          <span className="value">{m}</span>
          <span className="label">Minutes</span>
        </div>
        <div className="time-block">
          <span className="value">{s}</span>
          <span className="label">Seconds</span>
        </div>
      </div>
    );
  };

  return (
    // Use key={targetDate} to force a remount when the target date changes.
    <Countdown
      key={targetDate}
      date={targetDate}
      renderer={renderer}
      onComplete={handleComplete}
    />
  );
}
